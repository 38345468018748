@import "src/themes/variables.module";
@import "src/themes/mixins";

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.typo-page {
  display: flex;
  flex-direction: column;
  margin: var(--spacing) 0;
  --spacing: 50px;
  margin: 0 auto;

  > * {
    margin: 19px 0;
  }

  &.small {
    max-width: 1180px;
  }

  &.large {
    max-width: 1420px;
  }

  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  :global(.rtebutton.primary) {
    margin-bottom: 8px;
  }

  :global(.spaceBeforeextra-small) {
    margin-top: 1em;
  }

  :global(.spaceBeforesmall) {
    margin-top: 2em;
  }

  :global(.spaceBeforemedium) {
    margin-top: 3em;
  }

  :global(.spaceBeforelarge) {
    margin-top: 4em;
  }

  :global(.spaceBeforeextra-large) {
    margin-top: 5em;
  }

  :global(.spaceAfterextra-small) {
    margin-bottom: 1em;
  }

  :global(.spaceAftersmall) {
    margin-bottom: 2em;
  }

  :global(.spaceAftermedium) {
    margin-bottom: 3em;
  }

  :global(.spaceAfterlarge) {
    margin-bottom: 4em;
  }

  :global(.spaceAfterextra-large) {
    margin-bottom: 5em;
  }

  :global(.left), :global(.text-left) {
    text-align: left;
  }

  :global(.right), :global(.text-right) {
    text-align: right;
  }

  :global(.center), :global(.text-center) {
    text-align: center;
  }

  :global(.justify), :global(.text-justify) {
    text-align: justify;
  }

  :global(.d-block) {
    display: block;
  }

  :global(.rtebutton-base) {
    display: block;
    a {
      display: inline-block;
    }
  }

  :global(.bodytext-container), :global(.typography) {
    position: relative;
    overflow: auto;
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;
    font-family: hero-new;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.18px;
    margin: 0;
    padding: 0;
    border: none;
    z-index: 1;

    pre {
      white-space: normal;
      line-height: 1.3;
    }

    a {
      color: map-get($colors, "greys", "dark");
      font-weight: bold;
      &:hover {
        color: map-get($colors, "primary", "middleDark");
      }
    }

    > ul li {
      list-style: inherit;
      background: none;
      padding: 0;
    }

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    > * {
      margin-bottom: 19px;
    }

    > *:last-child {
      margin-bottom: 0;
    }

    ul,
    ol {
      list-style-position: outside;
      padding-left: 20px;
    }

    li ul,
    li ol {
      padding-left: 20px;
    }

    > table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid #{map-get($colors, "greys", "middleLight")};

      td,
      th {
        border-top: 1px solid #{map-get($colors, "greys", "middleLight")};
        border-bottom: 1px solid #{map-get($colors, "greys", "middleLight")};
      }

      th {
        background-color: map-get($colors, "greys", "lightDark");
        padding: 10px 0;
      }

      td {
        vertical-align: middle;
        height: 65px;
        padding: 10px;

        @media (max-width: 640px) {
          height: unset;
        }
      }
    }

    table:global(.swipe) {
      border-left: none;

      thead {
        th {
          &:first-child {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: map-get($colors, "greys", "lightDark");

            &:before {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 1px;
              background-color: map-get($colors, "greys", "middleLight");
              content: "";
            }
          }
        }
      }

      tbody {
        th,
        tr > td:first-child {
          position: sticky;
          left: 0;
          background-color: #FFFFFF;

          &:before {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 1px;
            background-color: map-get($colors, "greys", "middleLight");
            content: "";
          }
        }

        tr > td:first-child > strong {
          position: sticky;
          left: 10px;
        }

        td {
          background-clip: padding-box;
        }
      }
    }

    img {
      margin: 0 auto;
      display: block;
    }

    > p > img {
      width: 100%;
    }

    blockquote {
      padding: 15px 19px;
      border-left: 1px solid map-get($colors, "greys", "middleDark");
      color: map-get($colors, "greys", "dark");
      font-style: italic;
    }
  }

  :global(.bodytext-container) {
    padding-top: 15px;
    padding-bottom: 15px;

    > :first-child {
      padding-top: 19px;
    }
  }

  :global(.bodytext-padding) {
    padding: 0;
    > :first-child {
      padding-top: 0;
    }
  }
}

.inverted :global(.bodytext-container), .inverted :global(.typography) {
  color: map-get($colors, "greys", "light");

  a {
    color: map-get($colors, "greys", "light");
    &:hover {
      color: map-get($colors, "primary", "middleDark");
    }
  }

  th {
    color: map-get($colors, "greys", "dark")
  }

  blockquote {
    border-left: 1px solid map-get($colors, "greys", "middleLight");
    color: map-get($colors, "greys", "light");
  }
}

.loading {
  position: relative;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.loading-label, .loading-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.loading-content {
  width: 50px;
  justify-content: end;
  overflow: inherit;
}

.loading-dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
  animation: dotElastic 1s infinite linear;

  &::before, &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
    animation: dotElasticBefore 1s infinite linear;
  }

  &::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
    animation: dotElasticAfter 1s infinite linear;
  }
}

.inverted {
  background-color: map-get($colors, 'greys', 'dark');

  .loading-dot-elastic, .loading-dot-elastic::before, .loading-dot-elastic::after {
    background-color: #ffffff;
    color: #ffffff;
  }
}

.inverted {
  :global(.bodytext-container) {
    table:global(.swipe) {
      tbody {
        th,
        tr > td:first-child {
          background-color: #000000;
        }
      }
    }
  }
}

.stage-container {
  padding-top: 61px;
  & > div:first-child {
    width: 100%;
    position: relative;
    top: -181px;
    margin-bottom: -101px;
    z-index: 1;
  }
}

.news main {
  max-width: 1180px;
}

.fullwidth main {
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 400px) {
  .stage-container {
    padding-top: 91px;
  }
}