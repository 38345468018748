@import "src/themes/mixins";

.reading-progress-container {
  position: relative;
}

.reading-progress {
  position: fixed;
  top: 0;
  left: 0;
  height: 15px;
  background-color: map-get($colors, 'primary', 'middleDark');
  z-index: 9999;
}

.width-small {
  margin: 0 auto;
  max-width: 400px;
}

.width-medium {
  margin: 0 auto;
  max-width: 600px;
}

.width-large {
  margin: 0 auto;
  max-width: 800px;
}